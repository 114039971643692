import {SNACK_BAR_TYPE} from "../model/lib-snackbar.model";

export class LibSnackbarUtil {
    static getPanelClass(snackbarType: SNACK_BAR_TYPE): string[] {
        switch (snackbarType) {
            case SNACK_BAR_TYPE.WELCOME:
                return ['snack-bar-container', 'welcome-snack-bar'];
            case SNACK_BAR_TYPE.WARNING :
                return ['snack-bar-container', 'warning-snack-bar']
            case SNACK_BAR_TYPE.INFO :
                return ['snack-bar-container', 'info-snack-bar'];
            case SNACK_BAR_TYPE.SUCCESS :
                return ['snack-bar-container', 'success-snack-bar'];
            case SNACK_BAR_TYPE.DANGER :
                return ['snack-bar-container', 'danger-snack-bar'];
            default:
                return ['snack-bar-container', 'info-snack-bar'];
        }
    }
}
