import {DecodedLoginTokenDto} from "../../../home-layout/common/model/decoded-login-token.model";
import {jwtDecode} from "jwt-decode";

export class TokenUtil {

    static isTokenExpired(authToken: string): boolean {
        const expiryTime = this.getExpiryTime(authToken);
        if (expiryTime != null) {
            return ((1000 * expiryTime) - (new Date().getTime())) < 5000;
        } else {
            return false;
        }
    }

    static getExpiryTime(authToken: string): number {
        const decodedToken = this.decodeToken(authToken);
        return decodedToken.exp;
    }

    static decodeToken(accessToken: string): DecodedLoginTokenDto {
        return jwtDecode(accessToken);
    }
}
