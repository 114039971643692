import {Injectable} from '@angular/core';
import {NotificationOutDto} from '../model/notification.model';
import {LibSnackbarService} from "../../../../../tal-library/snackbar/lib-snackbar/common/service/lib-snackbar.service";
// TODO : Uncomment bellow code later
// import {NotificationSnackbarComponent} from "../../notification-snackbar/notification-snackbar.component";

@Injectable({
    providedIn: 'root'
})
export class PushNotificationService {

    isNotificationVisible = false;
    notificationsQueue = [];
    private NOTIFICATION_TIMEOUT = 5000;


    constructor(private libSnackbarService: LibSnackbarService) {
    }

    addToQueue(notificationOutDto: NotificationOutDto) {
        this.notificationsQueue.push(notificationOutDto);
        // this.show(); TODO : UNCOMMENT LATER
    }

    /*show(): void {
        if (!this.isNotificationVisible && this.notificationsQueue?.length > 0) {
            this.isNotificationVisible = true;
            this.libSnackbarService.openFromComponent(NotificationSnackbarComponent,
                this.getConfig(this.notificationsQueue.shift()))
                .afterDismissed().subscribe(this.handleCloseResult());
        }
    }*/

    private getConfig(notificationOutDto: NotificationOutDto): any {
        return {
            data: this.getData(notificationOutDto),
            duration: this.NOTIFICATION_TIMEOUT
        };
    }

    private getData(notificationOutDto: NotificationOutDto): any {
        return {
            data: notificationOutDto,
            timeout: this.NOTIFICATION_TIMEOUT
        };
    }

    private handleCloseResult(): () => void {
        return () => {
            this.isNotificationVisible = false;
            // this.show(); TODO : UNCOMMENT LATER
        };
    }
}
