import {ApplicationConfig} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideHttpClient} from "@angular/common/http";
import {FIREBASE_PROVIDERS} from "./talenlio-hireos/home-layout/common/constant/firebase.providers";
import {provideServiceWorker} from "@angular/service-worker";
import {environment as ENVIRONMENT} from "../environments/environment";
import {environment as PROD_ENVIRONMENT} from "../environments/environment.prod";

export const appConfig: ApplicationConfig = {
    providers: [
        provideHttpClient(),
        provideRouter(routes),
        provideAnimationsAsync(),
        FIREBASE_PROVIDERS,
        provideServiceWorker('ngsw-worker.js', {
            enabled: ENVIRONMENT.name === PROD_ENVIRONMENT.name,
            registrationStrategy: 'registerWhenStable:30000'
        }),
    ]
};
