import {Routes} from '@angular/router';
import {ROUTE} from "./talenlio-hireos/home-layout/common/constant/routing.constant";
import {authGuard} from "./talenlio-hireos/authentication/common/service/auth/auth.gaurd";

export const routes: Routes = [
    {
        path: '',
        canActivate: [authGuard],
        loadChildren: () => import('./talenlio-hireos/home-layout/home-layout.route')
            .then(route => route.HOME_LAYOUT_ROUTES)
    },
    {
        path: ROUTE.AUTH,
        loadChildren: () => import('./talenlio-hireos/authentication/authentication.route').then(route => route.AUTHENTICATION_ROUTES),
    },
    // TODO : Add LibPageNotFoundComponent
    /*{
        path: ROUTE.WILDCARD,
        component:""
    }*/
];
