<div class="flex flex-row items-center justify-between">
    <div [ngClass]="LibSnackbarUtil.getPanelClass(snackBarConfig?.type)[0]"
         class="flex flex-row justify-start items-center w-full">
        <img class="h-14 pt-2" [src]="snackBarConfig?.imageUrl" alt="">
        <div class="mx-2 font-normal text-base">{{ snackBarConfig?.message }}</div>
        @if (snackBarConfig?.type == SNACK_BAR_TYPE.WELCOME) {
            <div>👋</div>
        }
    </div>
    <div class="ti ti-x text-3xl text-slate-500 p-3 cursor-pointer"
         (click)="onCloseSnackBar()">
    </div>
</div>
