import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef} from "@angular/material/snack-bar";
import {ComponentType} from "@angular/cdk/overlay";
import {LibSnackbarComponent} from "../../lib-snackbar.component";
import {LibSnackBarConfig} from "../model/lib-snackbar.model";

@Injectable({
    providedIn: 'root'
})
export class LibSnackbarService {
    constructor(private matSnackBarRef: MatSnackBar) {
    }

    openSnackBar(snackBarConfig: LibSnackBarConfig): void {
        this.matSnackBarRef.openFromComponent(LibSnackbarComponent, this.setSnackbarConfig(snackBarConfig));
    }

    public openFromComponent<T>(component: ComponentType<T>, config?: MatSnackBarConfig): MatSnackBarRef<T> {
        config = this.getDefaultConfig(config);
        return this.matSnackBarRef.openFromComponent(component, config);
    }

    private setMatSnackbarConfigData(message?: string, snackbarType?: string, imageUrl?: string): LibSnackBarConfig {
        return {
            message: message,
            snackBarRef: this.matSnackBarRef,
            type: snackbarType,
            imageUrl: imageUrl
        } as LibSnackBarConfig
    }

    private setSnackbarConfig(snackBarConfig: LibSnackBarConfig): MatSnackBarConfig {
        return {
            data: this.setMatSnackbarConfigData(snackBarConfig?.message, snackBarConfig?.type, snackBarConfig?.imageUrl),
            duration: snackBarConfig?.duration,
            horizontalPosition: snackBarConfig?.horizontalPosition,
            verticalPosition: snackBarConfig?.verticalPosition,
            panelClass: snackBarConfig?.panelClass
        } as MatSnackBarConfig;
    }

    private getDefaultConfig(config: MatSnackBarConfig<any>): MatSnackBarConfig<any> {
        return config ? this.updateConfig(config) : {
            panelClass: 'custom-snackbar'
        };
    }

    private updateConfig(config: MatSnackBarConfig<any>): MatSnackBarConfig<any> {
        config.panelClass = 'custom-snackbar' + (config?.panelClass ? ' ' + config?.panelClass : '');
        config.horizontalPosition = config?.horizontalPosition || 'right';
        config.verticalPosition = config?.verticalPosition || 'bottom';
        config.duration = config?.duration || 5000;
        return config;
    }
}
