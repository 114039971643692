import {Injectable} from '@angular/core';
import {getMessaging, getToken} from '@angular/fire/messaging';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/compat/database';
import {UserMessagingFirebaseService} from './user-messaging-firebase.service';
import {DataSnapshot} from '@angular/fire/compat/database/interfaces';
import {DEVICE_TYPE, UserFcmTokenModel} from "../../model/user-fcm-token.model";
import {environment} from "../../../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class UserTokenFirebaseService {

    userFcmTokensRef: AngularFireList<UserFcmTokenModel>;
    loggedInUserId: string;
    private USERS_URL = '/users';
    private DEVICE_TOKENS_URL = '/deviceTokens';
    private FCM_TOKEN = 'fcmToken';

    constructor(private firebaseDatabase: AngularFireDatabase,
                private userMessagingFirebaseService: UserMessagingFirebaseService) {
    }

    create(userId: string): void {
        this.loggedInUserId = userId;
        this.setUserFcmTokenRef();
        this.registerServiceWorker();
    }

    delete(): Promise<DataSnapshot | null> {
        const token = this.getFCMToken();
        this.removeTokenFromLocalStorage();
        return this.checkAndDeleteTokenFromFirebaseRDB(token);
    }

    private removeTokenFromLocalStorage(): void {
        localStorage.removeItem(this.FCM_TOKEN);
    }

    private handleToken(): (currentToken: any) => void {
        return (currentToken) => {
            if (currentToken) {
                this.saveGeneratedToken(currentToken);
                this.userMessagingFirebaseService.listen();
            } else {
                console.log('<----- No registration token available. Request permission to generate one. ----->');
            }
        };
    }

    private generateFcmToken(): Promise<string> {
        return getToken(getMessaging(), {vapidKey: environment.firebase.vapidKey});
    }

    private saveGeneratedToken(currentToken: string): void {
        const previousToken = this.getFCMToken();
        if (!this.tokenExist(previousToken, currentToken)) {
            this.clearTokenIfChanged(previousToken, currentToken);
            this.saveToLocalStorage(currentToken);
            this.saveToFirebaseRDB(currentToken);
        }
    }

    private tokenExist(previousToken: string, currentToken: string): boolean {
        return previousToken && !this.isTokenChanged(previousToken, currentToken);
    }

    private getFCMToken(): string {
        return localStorage.getItem(this.FCM_TOKEN);
    }

    private saveToLocalStorage(currentToken: string): void {
        localStorage.setItem(this.FCM_TOKEN, currentToken);
    }

    private setUserFcmTokenRef(): void {
        this.userFcmTokensRef = this.firebaseDatabase
            .list(`${this.USERS_URL}/${this.loggedInUserId}/${this.DEVICE_TOKENS_URL}`);
    }

    private saveToFirebaseRDB(currentToken: string): void {
        const tokenModel: UserFcmTokenModel = {
            token: currentToken,
            deviceType: DEVICE_TYPE.WEB
        };
        if (this.userFcmTokensRef) {
            this.userFcmTokensRef.push(tokenModel).then(() => {
            });
        }
    }

    private handleTokenCreationError(): (error: any) => void {
        return (error) => {
            console.log('<----- An error occurred while retrieving token. -----> ', error);
        };
    }

    private clearTokenIfChanged(previousToken: string, currentToken: string): void {
        if (previousToken && this.isTokenChanged(previousToken, currentToken)) {
            this.checkAndDeleteTokenFromFirebaseRDB(previousToken).then(() => {
            });
        }
    }

    private isTokenChanged(previousToken: string, currentToken: string): boolean {
        return previousToken !== currentToken;
    }

    private checkAndDeleteTokenFromFirebaseRDB(token: string): Promise<DataSnapshot | null> {
        return this.getAllTokensFromFirebaseRDB().then((dataSnapshot) => {
            if (dataSnapshot === null) {
                console.log("No fcm tokens available to check.");
                return null;
            }
            dataSnapshot.forEach(this.handleExistingToken(token));
            return dataSnapshot;
        });
    }

    private handleExistingToken(token: string): (child: any) => void {
        return child => {
            const userFcmTokenModel: UserFcmTokenModel = child.val() as UserFcmTokenModel;
            if (userFcmTokenModel.token === token) {
                this.deleteTokenFromFirebaseRDB(child?.key);
            }
        };
    }

    private getAllTokensFromFirebaseRDB(): Promise<DataSnapshot | null> {
        if (this.userFcmTokensRef) {
            return this.userFcmTokensRef.query.once('value');
        }
        return Promise.resolve(null);
    }

    private deleteTokenFromFirebaseRDB(tokenId: string): void {
        this.userFcmTokensRef?.remove(tokenId).then(() => {
        });
    }

    private registerServiceWorker(): void {
        navigator.serviceWorker.register('firebase-messaging-sw.js', {type: 'classic', scope: '__'})
            .then(() => {
                this.generateFcmToken().then(this.handleToken()).catch(this.handleTokenCreationError());
            });
    }
}
