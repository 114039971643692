import {Component} from '@angular/core';
import {RouterModule, RouterOutlet} from '@angular/router';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {RequestInterceptor} from "./talenlio-hireos/authentication/common/service/auth/request-interceptor";
import {CommonModule} from "@angular/common";

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        CommonModule,
        RouterOutlet,
        RouterModule
    ],
    providers: [
        {
            //auth
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true
        }
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
    title = 'Talenlio - HireOs';
}
