import {Injectable} from '@angular/core';
import {HttpClientWrapperService} from "./http-client-wrapper.service";
import {Observable} from "rxjs";
import {UserMeOutDto} from "../model/user-me.model";

@Injectable({
    providedIn: 'root'
})
export class UserMeService {

    private userUrl = '/users';
    private meUrl = '/me';

    constructor(private httpClientWrapperService: HttpClientWrapperService) {
    }

    find(): Observable<UserMeOutDto> {
        return this.httpClientWrapperService.get(`${this.userUrl}${this.meUrl}`);
    }
}
