import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable, switchMap} from "rxjs";
import {RequestParam} from "../model/request-param";
import {RequestHeader} from "../model/request-header";
import {RequestHeaderUtil} from "../util/request-header.util";
import {SubDomainStore} from "../store/sub-domain-store.service";
import {CustomHttpUrlEncodingCodecUtil} from "../util/custom-http-url-encoding-codec.util";

@Injectable({
    providedIn: 'root'
})
export class HttpClientWrapperService {

    private apiBaseUrl: string;

    private readonly requestOptions = {
        params: new HttpParams(),
        headers: new HttpHeaders()
    };

    constructor(private readonly http: HttpClient,
                private subDomainStore: SubDomainStore) {
        this.subscribeApiUrl();
    }

    get<T>(url: string, requestParams?: RequestParam[], requestHeaders?: RequestHeader[]): Observable<T> {
        return this.waitForApiUrl().pipe(
            switchMap(apiBaseUrl => {
                this.setRequestOptions(requestParams, url);
                return this.http.get<T>(apiBaseUrl + url, this.requestOptions);
            })
        );
    }

    post<T>(url: string, data?: any, requestParams?: RequestParam[], requestHeaders?: RequestHeader[]): Observable<T> {
        return this.waitForApiUrl().pipe(
            switchMap(apiBaseUrl => {
                this.setRequestOptions(requestParams, url);
                return this.http.post<T>(apiBaseUrl + url, data, this.requestOptions);
            })
        );
    }

    put<T>(url: string, data?: any, requestParams?: RequestParam[], requestHeaders?: RequestHeader[]): Observable<T> {
        return this.waitForApiUrl().pipe(
            switchMap(apiBaseUrl => {
                this.setRequestOptions(requestParams, url);
                return this.http.put<T>(apiBaseUrl + url, data, this.requestOptions);
            })
        );
    }

    patch<T>(url: string, data?: any, requestParams?: RequestParam[], requestHeaders?: RequestHeader[]): Observable<T> {
        return this.waitForApiUrl().pipe(
            switchMap(apiBaseUrl => {
                this.setRequestOptions(requestParams, url);
                return this.http.patch<T>(apiBaseUrl + url, data, this.requestOptions);
            })
        );
    }

    delete(url: string, requestParams?: RequestParam[], requestHeaders?: RequestHeader[]) {
        return this.waitForApiUrl().pipe(
            switchMap(apiBaseUrl => {
                this.setRequestOptions(requestParams, url);
                return this.http.delete(apiBaseUrl + url, this.requestOptions);
            })
        );
    }

    deleteWithRequestBody(url: string, data?: any, requestParams?: RequestParam[], requestHeaders?: RequestHeader[]) {
        return this.waitForApiUrl().pipe(
            switchMap(apiBaseUrl => {
                const requestOptions = this.setRequestOptionsForDeleteWithRequestBody(requestParams, url, data);
                return this.http.delete(apiBaseUrl + url, requestOptions);
            })
        );
    }

    download(url: string): Observable<ArrayBuffer> {
        return this.waitForApiUrl().pipe(
            switchMap(apiBaseUrl => this.http.get(apiBaseUrl + url, {
                responseType: 'arraybuffer',
                headers: new HttpHeaders()
            }))
        );
    }

    private appendRequestParams(requestParams?: RequestParam[]): void {
        this.requestOptions.params = new HttpParams({encoder:new CustomHttpUrlEncodingCodecUtil()});
        if (requestParams !== null && requestParams !== undefined) {
            requestParams.forEach(requestParam =>
                this.requestOptions.params = this.requestOptions.params.append(requestParam.key, requestParam.value));
        }
    }

    private subscribeApiUrl(): void {
        this.subDomainStore.apiUrl.subscribe(apiUrl => {
            this.apiBaseUrl = apiUrl as string;
        });
    }

    private waitForApiUrl(): Observable<string> {
        return new Observable(observer => {
            const checkInterval = setInterval(() => {
                if (this.apiBaseUrl) {
                    clearInterval(checkInterval);
                    observer.next(this.apiBaseUrl);
                    observer.complete();
                }
            }, 100);
        });
    }

    private setRequestOptionsForDeleteWithRequestBody(requestParams: RequestParam[], url: string, data: any): any {
        this.appendRequestParams(requestParams);
        this.requestOptions.headers = RequestHeaderUtil.addJwtToken(url);
        return {
            params: this.requestOptions.params,
            headers: this.requestOptions.headers,
            body: data
        };
    }

    private setRequestOptions(requestParams: RequestParam[], url: string): void {
        this.appendRequestParams(requestParams);
        this.requestOptions.headers = RequestHeaderUtil.addJwtToken(url);
    }
}
