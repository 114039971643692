export class UserFcmTokenModel {
    deviceType: DEVICE_TYPE;
    token: string;
}

export enum DEVICE_TYPE {
    WEB = 'WEB',
    IOS = 'IOS',
    ANDROID = 'ANDROID'
}
