import {LibSnackbarUtil} from "../util/lib-snackbar.util";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";

export enum SNACK_BAR_TYPE {
    WELCOME = 'WELCOME',
    WARNING = 'WARNING',
    INFO = 'INFO',
    DANGER = 'DANGER',
    SUCCESS = 'SUCCESS'
}

export enum SNACK_BAR_IMAGE_URL {
    WELCOME = 'assets/images/snackbar/talenlio-logo-white-fill.png',
    WARNING = 'assets/images/snackbar/warning.png',
    INFO = 'assets/images/snackbar/info.png',
    DANGER = 'assets/images/snackbar/danger.png',
    SUCCESS = 'assets/images/snackbar/success.png'
}

export class LibSnackBarConfig {
    message: string;
    type: SNACK_BAR_TYPE;
    imageUrl: string;
    duration: number = 4000;
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    snackBarRef?: MatSnackBar;
    panelClass: string[];

    constructor(message: string = 'Excited, Coming soon',
                snackbarType: SNACK_BAR_TYPE = SNACK_BAR_TYPE.INFO) {
        this.initSnackBarConfig(message, snackbarType);
    }

    private initSnackBarConfig(message: string,
                               snackbarType: SNACK_BAR_TYPE): void {
        this.message = message;
        this.type = snackbarType;
        this.imageUrl = SNACK_BAR_IMAGE_URL[snackbarType];
        this.panelClass = LibSnackbarUtil.getPanelClass(snackbarType);
    }
}
