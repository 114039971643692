import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {inject} from "@angular/core";
import {map} from "rxjs/operators";
import {AuthStore} from "../../../../home-layout/common/store/auth-store.service";
import {TokenService} from "../token.service";
import {USER_TYPE} from "../../../../home-layout/common/model/user.model";
import {ROUTE} from "../../../../home-layout/common/constant/routing.constant";

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authStore = inject(AuthStore);
    const router = inject(Router);
    const tokenService = inject(TokenService);

    tokenService.setAccessToken(route.queryParamMap.get(ROUTE.MOBILE_TOKEN));
    if (authStore.isLoggedIn()) {
        navigateToDashboard(authStore, router);
        return true;
    } else {
        authStore.clearLocalStorage();
        navigateToLogin(router, state.url);
        return false;
    }
};

const navigateToDashboard = (authStore: AuthStore, router: Router) => {
    return authStore.getLoggedInUser().pipe(
        map(userMeOutDto => {
            if (userMeOutDto?.userOutDto?.userType === USER_TYPE.EMPLOYER) {
                router.navigate([ROUTE.SLASH + ROUTE.DASHBOARD]);
            }
        })
    );
};

const navigateToLogin = (router: Router, returnUrl: string): void => {
    router.navigate([ROUTE.SLASH, ROUTE.AUTH, ROUTE.LOGIN], {queryParams: {returnUrl}});
};
