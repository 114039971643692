import {HttpHeaders} from '@angular/common/http';
import {environment} from "../../../../../environments/environment";
import {ROUTE} from "../constant/routing.constant";

export class RequestHeaderUtil {

    private static readonly PUBLIC_API_URL = '/public/';

    static addJwtToken(url: string) {
        if (url.includes(this.PUBLIC_API_URL)) {
            return this.attachPublicApiKeyToHeaders();
        }
        return this.attachAuthApiKeyToHeaders();
    }

    static attachPublicApiKeyToHeaders(): HttpHeaders {
        return new HttpHeaders().append('api-key', environment.publicApiKey);
    }

    static attachAuthApiKeyToHeaders(): HttpHeaders {
        const accessToken = localStorage.getItem(ROUTE.TOKEN);
        if (accessToken) {
            return new HttpHeaders().append(ROUTE.AUTHORIZATION, accessToken);
        }
        return new HttpHeaders();
    }
}
